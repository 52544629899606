import axios from 'axios';
import Cookies from 'js-cookie';
import { getItem } from '../../utility/localStorageControl';
import responseActions from '../../redux/responseAction/actions';
import store from '../../redux/store';
import { notification } from 'antd';
const { dispatch } = store;

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('cf_internal_access_token')}`,
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('cf_internal_access_token')}`,
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

class DataService {
  static get(path = '', responseType = 'json') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
      responseType: responseType,
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;

  requestConfig.headers = { ...headers, Authorization: `Bearer ${Cookies.get('cf_internal_access_token')}` };

  dispatch(responseActions.apiCallBegin());
  return requestConfig;
});

client.interceptors.response.use(
  response => {
    dispatch(responseActions.cleanResponseStatus());
    return response;
  },
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    const originalRequest = error.config;
    if (response) {
      if (response.status) {
        dispatch(responseActions.cleanResponseStatus());

        let message = `Something went wrong`;
        notification.warning({
          message,
          description: response.data?.message || response.messages?.map(el => el.info).toString(),
          duration: 8,
        });

        if (response.status === 403) {
          try {
            if (Cookies.get('cf_user')) {
            }
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        return originalRequest;
      }
    } else {
      dispatch(responseActions.cleanResponseStatus());
    }
    return Promise.reject(error);
  },
);
export { DataService };
