const actions = {
  SET_TOKEN: 'SET_TOKEN',
  READ_PROFILE_ECOMMERCE: 'READ_PROFILE_ECOMMERCE',
  READ_PROFILE_CUSTOMER_ECOMMERCE: 'READ_PROFILE_CUSTOMER_ECOMMERCE',
  GET_CATEGORIES_ECOMMERCE: 'GET_CATEGORIES_ECOMMERCE',
  GET_PRODUCTS_ECOMMERCE: 'GET_PRODUCTS_ECOMMERCE',
  GET_PRODUCT_BY_ID_ECOMMERCE: 'GET_PRODUCT_BY_ID_ECOMMERCE',
  SAVE_SHIPMENT: 'SAVE_SHIPMENT',
  SAVE_BUY: 'SAVE_BUY',
  SAVE_TAX: 'SAVE_TAX',
  CLEAN_PRODUCT_ECOMMERCE: 'CLEAN_PRODUCT_ECOMMERCE',
  CLEAN_BUY_ECOMMERCE: 'CLEAN_BUY_ECOMMERCE',
  ACTION_BEGIN: 'ACTION_BEGIN',
  ERROR: 'ERROR',
  JOB_SESSION: 'JOB_SESSION',
  SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
  RESPONSE_STATUS_ECOMMERCE: 'RESPONSE_STATUS_ECOMMERCE',
  CLEAN_RESPONSE_STATUS_ECOMMERCE: 'CLEAN_RESPONSE_STATUS_ECOMMERCE',
  GET_ADDRESS_BOOKS_ECOMMERCE: 'GET_ADDRESS_BOOKS_ECOMMERCE',
  GET_ADDRESS_BOOK_BY_ID_ECOMMERCE: 'GET_ADDRESS_BOOK_BY_ID_ECOMMERCE',
  CLEAN_ADDRESS_BOOK_ECOMMERCE: 'CLEAN_ADDRESS_BOOK_ECOMMERCE',
  CLEAN_PROFILE_CUSTOMER_ECOMMERCE: 'CLEAN_PROFILE_CUSTOMER_ECOMMERCE',

  GET_ORDER_ECOMMERCE: 'GET_ORDER_ECOMMERCE',
  GET_ORDERS_ECOMMERCE: 'GET_ORDERS_ECOMMERCE',
  CLEAN_ORDERS_ECOMMERCE: 'CLEAN_ORDERS_ECOMMERCE',

  CLEAN_SHIPMENTS: 'CLEAN_SHIPMENTS',
  CLEAN_TAXES: 'CLEAN_TAXES',
  GET_ORDER_ADDITIONAL_INFO_ECOMMERCE: 'GET_ORDER_ADDITIONAL_INFO_ECOMMERCE',

  tokenSuccess: data => {
    return { type: actions.SET_TOKEN, data };
  },
  readProfileCustomerSuccess: data => {
    return { type: actions.READ_PROFILE_CUSTOMER_ECOMMERCE, data };
  },
  readProfileSuccess: data => {
    return { type: actions.READ_PROFILE_ECOMMERCE, data };
  },
  categoriesSuccess: data => {
    return { type: actions.GET_CATEGORIES_ECOMMERCE, data };
  },
  productsSuccess: data => {
    return { type: actions.GET_PRODUCTS_ECOMMERCE, data };
  },
  productSuccess: data => {
    return { type: actions.GET_PRODUCT_BY_ID_ECOMMERCE, data };
  },
  cleanProductAction: () => {
    return { type: actions.CLEAN_PRODUCT_ECOMMERCE };
  },

  shipmentsSuccess: data => {
    return { type: actions.SAVE_SHIPMENT, data };
  },

  taxSuccess: data => {
    return { type: actions.SAVE_TAX, data };
  },

  saveSuccess: data => {
    return { type: actions.SAVE_BUY, data };
  },
  cleanBuyAction: () => {
    return { type: actions.CLEAN_BUY_ECOMMERCE };
  },
  actionBegin: data => {
    return { type: actions.ACTION_BEGIN, data };
  },
  error: err => {
    return { type: actions.ERROR, err };
  },
  saveJobSession: data => {
    data.template = data.template || data.template_id;
    data.template_id = data.template_id || data.template;

    return { type: actions.JOB_SESSION, data };
  },
  setCustomerId: data => {
    return { type: actions.SET_CUSTOMER_ID, data };
  },
  actionResponseStatus: data => {
    return { type: actions.RESPONSE_STATUS_ECOMMERCE, payload: data };
  },
  actionCleanResponseStatus: () => {
    return { type: actions.CLEAN_RESPONSE_STATUS_ECOMMERCE };
  },

  addressBooksSuccess: data => {
    return { type: actions.GET_ADDRESS_BOOKS_ECOMMERCE, data };
  },
  addressBookSuccess: data => {
    return { type: actions.GET_ADDRESS_BOOK_BY_ID_ECOMMERCE, data };
  },
  cleanAddressBookAction: () => {
    return { type: actions.CLEAN_ADDRESS_BOOK_ECOMMERCE };
  },
  cleanProfileCustomerEcommerceAction: () => {
    return { type: actions.CLEAN_PROFILE_CUSTOMER_ECOMMERCE };
  },

  getOrdersAction: data => {
    return { type: actions.GET_ORDERS_ECOMMERCE, data };
  },
  getOrderAction: data => {
    return { type: actions.GET_ORDER_ECOMMERCE, data };
  },
  cleanOrdersAction: () => {
    return { type: actions.CLEAN_ORDERS_ECOMMERCE };
  },

  cleanShipmentsAction: () => {
    return { type: actions.CLEAN_SHIPMENTS };
  },

  cleanTaxesAction: () => {
    return { type: actions.CLEAN_TAXES };
  },
  getOrderAdditionalInfoAction: data => {
    return { type: actions.GET_ORDER_ADDITIONAL_INFO_ECOMMERCE, data };
  },
};

export default actions;
